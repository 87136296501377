import React, { useState, useEffect } from 'react';
import { AllRoutes } from './helpers/AllRoutes';

function App() {

  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
